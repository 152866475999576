import styles from './index.module.css'
import drawer from './../../assets/img/Drawer@2x.png'
import refresh from './../../assets/img/refresh.png'
import tl from './../../assets/img/tl.png'
import m1 from './../../assets/img/m1.png'
import m2 from './../../assets/img/m2.png'
import m4 from './../../assets/img/m4.png'
import Echart from './echart'
import axios from 'axios'
import config from './../../assets/js/config'
import CenterAnimate from '@/components/centerAnimate'
import { DatePicker, Select } from 'antd'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
const { RangePicker } = DatePicker

//江门
const JiangMen = 'https://admin.stdag.cn/back3/#/digitalFarm2/digitalFarm'
//茂名
const MaoMin =
  'https://www.zhy.club/wulian/index_back.html#/system/sys_zhywl/manage/listHome'
//从化
const CongHua = 'http://139.9.72.235/bigscreen/#/netFisheries'
//南沙
const NanSha = 'http://monitor.chlitchi.com/Home/Main'

//佛山市 - 高明
const FouShanGaoMin = 'http://farm.sino-eco.com/website/gaoming_dashboard/'

export default function Map(props) {
  const {
    isHide,
    isAni,
    aniData,
    loadMarkPoint,
    initDatePicker,
    defaultStartDate,
    defaultEndDate,
    handleChangeDate,
    handleChangeType,
    handleChangeLevel,
    handleCenterChange,
    reset,
    typeValue,
    levelValue,
  } = props

  useEffect(() => {
    getSortList()
    handleCenterChange()
  }, [])

  const [typeList, setTypeList] = useState()
  const [leveList, setLeveList] = useState()
  // const [isAni, setIsAni] = useState(false)

  const getSortList = () => {
    axios(`${config.baseUrl}/sortList`).then(function (res) {
      const typeList = res.data.typeList
      const leveList = res.data.leveList
      let typeList_m = []
      let leveList_m = []
      typeList.forEach((item) => {
        typeList_m.push({
          value: item,
          label: item,
        })
      })
      leveList.forEach((item) => {
        leveList_m.push({
          value: item,
          label: item,
        })
      })
      setTypeList(typeList_m)
      setLeveList(leveList_m)
    })
  }

  // const handleChange = (value) => {
  //     console.log(`selected ${value}`);
  // };

  // const handleCenterChange = () => {
  //   setIsAni(!isAni)
  //   // if (!isAni) {
  //   reset()
  //   // }
  // }

  const separator = <div>~</div>
  const suffixIcon = <img className="arrow" src={drawer} alt="" />

  const a = new Date().getTime() //  当前时间
  const b = new Date().getTime() + 30 * 60 * 1000 // 当前

  return (
    <div className={`${styles.mapBox} mapboxCon`}>
      <div className={styles.operations}>
        <div className={styles.selectBox}>
          <div className={`${styles.dateBox}`}>
            <span>日期</span>
            <i></i>
            <div className={styles.datepickerBox}>
              {/* defaultValue={[defaultStartDate, defaultEndDate]}  */}
              {/* <RangePicker locale={locale} defaultValue={[dayjs(defaultStartDate), dayjs(defaultEndDate)]} className={styles.datePicker} bordered={false} separator={separator} suffixIcon={suffixIcon} allowClear={false} onChange={handleChangeDate}/> */}
              {!initDatePicker && (
                <RangePicker
                  locale={locale}
                  className={styles.datePicker}
                  bordered={false}
                  separator={separator}
                  suffixIcon={suffixIcon}
                  allowClear={false}
                  onChange={handleChangeDate}
                />
              )}
            </div>
          </div>
          <div className={styles.dateBox}>
            <span>项目类型</span>
            <i></i>
            {typeList && (
              <Select
                defaultValue={typeList[0].label}
                value={typeValue}
                onChange={handleChangeType}
                suffixIcon={suffixIcon}
                className={styles.select}
                options={typeList}
              />
            )}
          </div>
          <div className={styles.dateBox}>
            <span>项目等级</span>
            <i></i>
            {leveList && (
              <Select
                defaultValue={leveList[0].label}
                value={levelValue}
                onChange={handleChangeLevel}
                suffixIcon={suffixIcon}
                className={styles.select}
                options={leveList}
              />
            )}
          </div>
          <div className={styles.toggle} onClick={() => handleCenterChange()}>
            防灾减损全景图
          </div>
        </div>
        <div className={styles.refresh} onClick={() => reset()}>
          <img src={refresh} title="刷新" alt="刷新" />
        </div>
      </div>
      <CenterAnimate isAni={isAni} aniData={aniData} />
      <div
        className={`${styles.chartContainer} ${
          isAni ? styles.none : styles.flex
        }`}
      >
        <Echart />
        {loadMarkPoint && (
          <>
            {/* <a
              className={`${isHide ? styles.xzftBox : ''} ${styles.fruitBox}`}
              href={MaoMin}
              target="_blank"
            >
              <div className={styles.fruit}>
                <img src={m1} />
                <div className={styles.line1}></div>
                <div className={styles.line2}></div>
              </div>
            </a>
            <a
              className={`${isHide ? styles.xzftBox : ''} ${styles.fruitBox} ${
                styles.RiceBox
              }`}
              href={JiangMen}
              target="_blank"
            >
              <div className={styles.fruit}>
                <img src={m2} />
                <div className={styles.line1}></div>
                <div className={styles.line2}></div>
              </div>
            </a>
            <a
              className={`${isHide ? styles.xzftBox : ''} ${styles.fruitBox} ${
                styles.fruitBox2
              }`}
              href={NanSha}
              target="_blank"
            >
              <div className={styles.fruit}>
                <img src={m1} />
                <div className={styles.line1}></div>
                <div className={styles.line2}></div>
              </div>
            </a>
            <a
              className={`${isHide ? styles.xzftBox : ''} ${styles.fruitBox} ${
                styles.fruitBox3
              }`}
              href={CongHua}
              target="_blank"
            >
              <div className={styles.fruit}>
                <img src={m4} />
                <div className={styles.line1}></div>
                <div className={styles.line2}></div>
              </div>
            </a>
            <a
              className={`${isHide ? styles.xzftBox : ''} ${styles.fruitBox} ${
                styles.fruitBox4
              }`}
              href={FouShanGaoMin}
              target="_blank"
            >
              <div className={styles.fruit}>
                <img src={m2} />
                <div className={styles.line1}></div>
                <div className={styles.line2}></div>
              </div>
            </a> */}
          </>
        )}
        <div className={styles.mapLegend}>
          {/* <div className={styles.mlTitle}>项目数量图例</div>
              <div className={styles.mlDesc}>(单位)</div>
              <div className={`${styles.tl} ${styles.tl1}`}><i></i>项目数量 </div>
              <div className={`${styles.tl} ${styles.tl2}`}><i></i>项目金额</div> */}
          <img src={tl} />
        </div>
      </div>
    </div>
  )
}
