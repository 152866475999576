import styles from "./index.module.css";
import Title from "./../title/title";
import close from "./../../assets/img/close.png";
import star from "./../../assets/img/star.png";
import starA from "./../../assets/img/star-a.png";
import { useState } from 'react';
import { Modal } from 'antd';


// 预警信息
const warningInformation = (info) => {
    return <>
        <div className={styles.yj_title}>{info.title}</div>
        <div className={styles.yj_line}></div>
        <p className={styles.auther}>
            <span>来源：{info.from}</span>
            <span>发布时间：{info.date}</span>
        </p>
        <div className={styles.articleBox}>
           <div dangerouslySetInnerHTML={{__html:info.content}}></div>
        </div>
    </>
}

// 项目一览
const projectList = (info) => {
  return <div className={styles.expertInterrogationBox}>
    <div className={styles.question}>
      <div className={styles.dashedBox}><span className={styles.dashedTit}>项目基本信息</span><span className={styles.dashed}></span></div>
      <div className={styles.itemkeyVal}>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>项目名称：</div><div className={styles.zj_value}>{info.name}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>项目区域：</div><div className={styles.zj_value}>{info.area}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>项目类型：</div><div className={styles.zj_value}>{info.type}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>项目周期：</div><div className={styles.zj_value}>{info.date} ~ {info.date_end}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>项目规模：</div><div className={styles.zj_value}>{info.project_size}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>单位：</div><div className={styles.zj_value}>{info.project_entity}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>项目资金：</div><div className={styles.zj_value}>{info.project_money}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>受益农户数：</div><div className={styles.zj_value}>{info.synhs}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>减损增效金额(万元)：</div><div className={styles.zj_value}>{info.jszxje}</div>
        </div>
        {/* <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>典型案例：</div><div className={styles.zj_value}>{info.project_demo}</div>
        </div> */}
      </div>
    </div>
    <div className={`${styles.question} ${styles.answer}`}>
      <div className={styles.dashedBox}><span className={styles.dashedTit}>项目简介</span><span className={styles.dashed}></span></div>
      <div className={styles.projecrDesc}>
        {info.project_introduce}
      </div>
    </div>
    <div className={`${styles.question} ${styles.answer}`}>
      <div className={styles.dashedBox}><span className={styles.dashedTit}>项目成效</span><span className={styles.dashed}></span></div>
      <div className={styles.projecrDesc}>
        {info.project_result}
      </div>
    </div>
    <div className={`${styles.question} ${styles.answer}`}>
      <div className={styles.dashedBox}><span className={styles.dashedTit}>项目影像</span><span className={styles.dashed}></span></div>
      <div className={`${styles.projecrDesc} ${styles.projecrDescYx}`}>
        {info.project_images && Array.isArray(info.project_images) && info.project_images.map((item,i) => <img className={styles.xm_img} key={item + i} src={item} />)}
      </div>
    </div>
  </div>
}

// 专家连线
const expertConnection = (info) => {
  return <div className={styles.expertListBox}>
    {info.map((item,i) => {
      return <div className={styles.zj_item} key={item.image + i}>
              <img className={styles.zj_head} src={item.image} />
              <div className={styles.infoBox}>
                <div className={styles.zj_name}><span className={styles.zj_name_span}>{item.nickname}</span><span className={styles.zj_name_i} style={{background:item.online == '离线' ? '#153e6c' : ''}}>{item.online}</span></div>
                <div className={styles.zj_info}><span className={styles.zi_title}>单位：</span><span className={styles.zj_value}>{item.company}</span></div>
                <div className={styles.zj_info}><span className={styles.zi_title}>职称：</span><span className={styles.zj_value}>{item.job}</span></div>
                {/* <div className={`${styles.zj_info} ${styles.zj_infoarea}`}><span className={styles.zi_title}>研究领域：</span><span className={`${styles.zj_value} eli1`}>{item.area}</span></div> */}
                <div className={styles.zj_info}><span className={styles.zi_title}>研究领域：</span><span className={styles.zj_value}>{item.area}</span></div>
                <div className={styles.zj_buttonBox}><div className={styles.zj_lxzj}>联系专家</div></div> 
              </div>
            </div>
    })}
  </div>
}

//专家问诊
const expertInterrogation = (info) => {
  const starANum = info.star
  let startList = [];
  
  for (let i = 1; i <= 5; i++) {
    if(i <= starANum){
      startList.push(<img key={i} className={styles.starClass} src={starA} alt=""/>)
    }else{
      startList.push(<img key={i} className={styles.starClass} src={star} alt=""/>)
    }
  }

  return <div className={styles.expertInterrogationBox}>
    <div className={styles.question}>
      <div className={styles.dashedBox}><span className={styles.dashedTit}>问题</span><span className={styles.dashed}></span></div>
      <div className={styles.itemkeyVal}>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>昵称：</div><div className={styles.zj_value}>{info.nickname}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>留言日期：</div><div className={styles.zj_value}>{info.date}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>主题：</div><div className={styles.zj_value}>{info.title}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>项目类型：</div><div className={styles.zj_value}>{info.type}</div>
        </div>
        <div className={`${styles.itemkeyValItem} ${styles.itemkeyValItem100}`}>
          <div className={styles.zi_title}>内容：</div><div className={styles.zj_value}>{info.content}</div>
        </div>
      </div>
    </div>
    <div className={`${styles.question} ${styles.answer}`}>
      <div className={styles.dashedBox}><span className={styles.dashedTit}>答复</span><span className={styles.dashed}></span></div>
      <div className={styles.itemkeyVal}>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>答复专家：</div><div className={styles.zj_value}>{info.reply.name}</div>
        </div>
        <div className={styles.itemkeyValItem}>
          <div className={styles.zi_title}>答复时间：</div><div className={styles.zj_value}>{info.reply.date}</div>
        </div>
        <div className={`${styles.itemkeyValItem} ${styles.itemkeyValItem100}`}>
          <div className={styles.zi_title}>内容：</div><div className={styles.zj_value}>{info.reply.content}</div>
        </div>
      </div>
    </div>
    <div className={`${styles.question} ${styles.answer}`}>
      <div className={styles.dashedBox}><span className={styles.dashedTit}>满意度评价</span><span className={styles.dashed}></span></div>
      <div className={`${styles.itemkeyValItem} ${styles.itemkeyValItem100}`}>
        <div className={styles.zi_title}>满意度：</div><div className={styles.zj_value}>
          {startList}
        </div>
      </div>
    </div>
  </div>
}



export default function ModalFrame(props) {
    // type:1 -> 预警信息; 2 -> 项目一览; 3 -> 专家连线; 4 -> 专家问诊
    const {title, type, open, setOpen, wrapClassName, info} = props;

    return info && (
        <Modal
          centered
          wrapClassName={`baseClass ${wrapClassName}`}
          destroyOnClose={false}
          closable={false}
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
        >
          <div className={styles.header}>
            <Title className={styles.modalTitle} title={title} />
            <div className={styles.closeBox} onClick={() => setOpen(false)}><img src={close} title="关闭" alt="close"/></div>
          </div>
          <div className={styles.content}>
            <div className={`${styles.scrollBox} scroll`}>
                {type == 1 && warningInformation(info)}
                {type == 2 && projectList(info)}
                {type == 3 && expertConnection(info)}
                {type == 4 && expertInterrogation(info)}
            </div>
          </div>
        </Modal>
    )
}
