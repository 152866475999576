import styles from './head.module.css'
import head_split from './../../assets/img/head_split.png'
import head_full from './../../assets/img/head_full.png'
import t from './../../assets/img/t.png'
import logoicon from './../../assets/img/logo-white.png'
//logoicon.png
let flag = false

const enterfullscreen = () => {
  //进入全屏
  const docElm = document.documentElement
  //W3C
  if (docElm.requestFullscreen) {
    docElm.requestFullscreen()
  }
  //FireFox
  else if (docElm.mozRequestFullScreen) {
    docElm.mozRequestFullScreen()
  }
  //Chrome等
  else if (docElm.webkitRequestFullScreen) {
    docElm.webkitRequestFullScreen()
  }
  //IE11
  else if (docElm.msRequestFullscreen) {
    docElm.msRequestFullscreen()
  }
}

const exitfullscreen = () => {
  //退出全屏
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.msExitFullscreen) {
    document.msExiFullscreen()
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  }
}

const isFullScreen = () => {
  return !!(
    document.fullscreen ||
    document.mozFullScreen ||
    document.webkitIsFullScreen ||
    document.webkitFullScreen ||
    document.msFullScreen
  )
}

const fullScreen = () => {
  if (flag) {
    exitfullscreen()
    return
  }
  enterfullscreen()
}

const listener = () => {
  document.addEventListener('fullscreenchange', function (event) {
    if (document.fullscreenElement) {
      flag = true
      console.log(123)
    } else {
      flag = false
      console.log(456)
    }
  })
  document.addEventListener('keydown', function (e) {
    if (e.keyCode === 122) {
      e.preventDefault()
      e.stopPropagation()
    }
  })
}

export default function Head(params) {
  listener()

  return (
    <div className={`${styles.header} app-top`}>
      <img className={styles.logoicon} src={logoicon} />
      {/* <div className={styles.title}>广东省防灾减损全景图</div> */}
      <div className={styles.title}>
        <img src={t} />
      </div>
      <div className={styles.fullScreen}>
        <img className={styles.split} src={head_split} />
        <img
          id="fullScreen"
          className={styles.full}
          src={head_full}
          onClick={fullScreen}
          title="全屏"
          alt="全屏"
        />
      </div>
    </div>
  )
}
