import styles from "./index.module.css";
import Title from "./../title/title";
import Modal from "./../modal/index";
// import data from './../../json/specia_list.json'
import { useEffect, useState } from 'react';
import { Carousel } from 'antd';
// import { List, Spin } from 'antd';
// import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios'
import config from "./../../assets/js/config";

export default function ProjectOverview() {

    useEffect(() => {
        getData()
    },[])

    const [data,setData] = useState([]);
    const [info, setInfo] = useState()
    const [open, setOpen] = useState(false);
    const getData = () => {
        axios(`${config.baseUrl}/plusstatic`).then(res => {
            setData(res.data)
        });
    }

    const getModalData = () => {
        axios(`${config.baseUrl}/specia_line`).then(res => {
            setInfo(res.data)
            setOpen(true)
        });
    }

    // const showDetail = (info) => {
    //     setInfo(info)
    //     setOpen(true)
    // }

    return <div className={styles.projectOverview}>
        <Title title='增值服务' />
        {/* <Modal title="专家问诊记录" type={4} open={open} setOpen={setOpen} info={info}/>
        <div className={styles.content}>
            <div className={`${styles.scrollBox} `} id="warningInformation">
                <Carousel autoplay infinite={true} dots={false} vertical={true} slidesToShow={3} slidesToScroll={1}>
                    {data.map(item => {
                        return <div key={item.number}>
                            <div className={styles.item}  onClick={() => showDetail(item)}>
                                <div className={styles.dealNo}>
                                    <div className={styles.title}>
                                        <i></i>
                                        <p className="eli">受理编号: {item.number}</p>
                                    </div>
                                    <div className={styles.date}>答复日期: {item.reply.date}</div>
                                </div>
                                <div className={`${styles.theme} eli`}>问诊主题：{item.title}</div>
                            </div>
                        </div>
                    })}
                </Carousel>
            </div>
        </div> */}
        {/* <Modal title="专家问诊记录" type={4} open={open} setOpen={setOpen} info={info}/> */}
        <Modal title="专家连线" type={3} open={open} setOpen={setOpen} info={info}/>
        <div className={styles.content}>
           <div className={styles.insideBox}>
                <div className={styles.row}>
                    <a className={styles.rowItem} target="_blank" href="https://ynb.piccgd.com:6443/fzjs/admin/index.html#/project/warning_list_view">
                        <div className={styles.rowNum}>{data.warning_count}<span>条</span></div>
                        <div className={styles.rowInfo}>预警信息</div>
                    </a>
                    <a className={styles.rowItem} target="_blank" href="https://ynb.piccgd.com:6443/fzjs/admin/index.html#/project/farmwork_info_view">
                        <div className={styles.rowNum}>{data.farm_work_count}<span>条</span></div>
                        <div className={styles.rowInfo}>农事管理</div>
                    </a>
                </div>
                <div className={styles.row}>
                    <a className={styles.rowItem} target="_blank" href="https://ynb.piccgd.com:6443/fzjs/admin/index.html#/project/expert_consultation_view">
                        <div className={styles.rowNum}>{data.askline_count}<span>条</span></div>
                        <div className={styles.rowInfo}>线上问诊</div>
                    </a>
                    <a className={styles.rowItem} onClick={getModalData}>
                        <div className={styles.rowNum}>{data.specia_count}<span>名</span></div>
                        <div className={styles.rowInfo}>智库专家</div>
                    </a>
                </div>
           </div>
        </div>
    </div>
}