import styles from "./index.module.css";
import Title from "./../title/title";
// import data from './../../json/overview.json'
import axios from 'axios'
import config from "./../../assets/js/config";
import { useEffect, forwardRef, useImperativeHandle, useState } from "react";
import { Carousel } from 'antd';
import x1 from "./../../assets/img/x1.png";
import x2 from "./../../assets/img/x2.png";
import x3 from "./../../assets/img/x3.png";
import x4 from "./../../assets/img/x4.png";
import x5 from "./../../assets/img/x5.png";
import x6 from "./../../assets/img/x6.png";



const ProjectOverview = forwardRef((props,ref) => {
    const { defaultDate,handleChangeYear } = props
    useEffect(()=>{
        getData()
    },[])

    const [dataAll,setDataAll] = useState({})
    const [data,setData] = useState({})
    const [options,setOptions] = useState()
    const [cyear, setCYear] = useState()
    const [defaultValue,setDefaultValue] = useState()
    const getData = (obj = {}) => {
        const {date, type, level, year} = obj
        axios(`${config.baseUrl}/overview?date=${date || defaultDate}&type=${type}&level=${level}&year=${year}`).then((res) => {   
            if(!res?.data) return
            const keys = Object.keys(res.data)
            let year = cyear
            if(!cyear){
                year = new Date().getFullYear() - 1
                setCYear(year)
            }
            const options = keys.map(item => {
                return {value: item,label: item}
            })
            setOptions(options)
            setDataAll(res.data)
            setData(res.data[year])
            setDefaultValue(year)
        });
    }

    const handleChange = (value) => {
        setCYear(value)
        // setData(dataAll[value])
        console.log('value',value)
        setData((pre) => {
            const newData = Object.assign({},pre,dataAll[value])
            return newData
        })
        handleChangeYear(value)
    }

    const [autoplay,setAutoplay] = useState(false)
    useEffect(()=>{
        setInterval(() => {
            setAutoplay(true)
            const timer = setTimeout(() => {
                clearTimeout(timer)
                setAutoplay(false)
            }, 5000);
        }, 10000);
    },[])
    
    
    useImperativeHandle(ref, ()=>({
        getData:(e)=>{
            getData(e)
        }
    }))

    
    
    return <div className={styles.projectOverview}>
        <Title title='项目总览' showSelect={true} options={options} handleChange={handleChange} defaultValue={defaultValue} />
        {
            data && <div className={styles.content}>
            <div className={styles.item}>
                <div className={styles.tit}>项目数量</div>
                <div className={styles.dash}></div>
                <div className={styles.xImgBox}><img className={styles.xImg} src={x1} /></div>
                <Carousel className={styles.numScroll} autoplay={autoplay} speed={500} dots={false} vertical={true} slidesToShow={1} slidesToScroll={1}>
                    <div className={styles.num}>{data.project_all}<span>个</span></div>
                    <div className={styles.num}>{data.project_all}<span>个</span></div>
                </Carousel>
            </div>
            <div className={styles.item}>
                <div className={styles.tit}>投入金额</div>
                <div className={styles.dash}></div>
                <div className={styles.xImgBox}><img className={styles.xImg} src={x2} /></div>
                <Carousel className={styles.numScroll} autoplay={autoplay} speed={500} dots={false} vertical={true} slidesToShow={1} slidesToScroll={1}>
                    <div className={styles.num}>{data.investment_montey}<span>万元</span></div>
                    <div className={styles.num}>{data.investment_montey}<span>万元</span></div>
                </Carousel>
            </div>
            <div className={styles.item}>
                <div className={styles.tit}>支出占比</div>
                <div className={styles.dash}></div>
                <div className={styles.xImgBox}><img className={styles.xImg} src={x3} /></div>
                <Carousel className={styles.numScroll} autoplay={autoplay} speed={500} dots={false} vertical={true} slidesToShow={1} slidesToScroll={1}>
                    <div className={styles.num}>{data.disaster_percent}<span>%</span></div>
                    <div className={styles.num}>{data.disaster_percent}<span>%</span></div>
                </Carousel>
            </div>
            <div className={styles.item}>
                <div className={styles.tit}>减损增效金额</div>
                <div className={styles.dash}></div>
                <div className={styles.xImgBox}><img className={styles.xImg} src={x5} /></div>
                <Carousel className={styles.numScroll} autoplay={autoplay} speed={500} dots={false} vertical={true} slidesToShow={1} slidesToScroll={1}>
                    <div className={styles.num}>{data.lower_loss}<span>亿元</span></div>
                    <div className={styles.num}>{data.lower_loss}<span>亿元</span></div>
                </Carousel>
            </div>
            <div className={styles.item}>
                <div className={styles.tit}>覆盖农户数量</div>
                <div className={styles.dash}></div>
                <div className={styles.xImgBox}><img className={styles.xImg} src={x4} /></div>
                <Carousel className={styles.numScroll} autoplay={autoplay} speed={500} dots={false} vertical={true} slidesToShow={1} slidesToScroll={1}>
                    <div className={styles.num}>{data.covering_farmers}<span>万户</span></div>
                    <div className={styles.num}>{data.covering_farmers}<span>万户</span></div>
                </Carousel>
            </div>
            <div className={styles.item}>
                <div className={styles.tit}>直接受益农户</div>
                <div className={styles.dash}></div>
                <div className={styles.xImgBox}><img className={styles.xImg} src={x6} /></div>
                <Carousel className={styles.numScroll} autoplay={autoplay} speed={500} dots={false} vertical={true} slidesToShow={1} slidesToScroll={1}>
                    <div className={styles.num}>{data.benefit_farmers}<span>万户</span></div>
                    <div className={styles.num}>{data.benefit_farmers}<span>万户</span></div>
                </Carousel>
            </div>
        </div>
        }
        
    </div>

   
})

export default ProjectOverview
