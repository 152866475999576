import styles from "./title.module.css";
import { Select } from 'antd';
import drawer from "./../../assets/img/Drawer@2x.png";


const arrow = () => {
    return <img className="arrow" src={drawer} ></img>
}

export default function Title(props) {
    return (
        <div className={`${styles.title} titleBox`} >
            <div className={[styles.item,props.hover ? styles.hoverStyle : ''].join(' ')}>
                <i></i><p className={styles.marginTop10}> <a href={props.link} target="_blank">{props.title}</a><span>&nbsp;{props.year}</span></p>
            </div>
            {props.showSelect && props.options && props.options.length && <Select
                defaultValue={props.defaultValue || props.options[0].label}
                onChange={props.handleChange}
                suffixIcon={arrow()}
                className={styles.select}
                options={props.options}
                style={props.style}
                />
            }
        </div>
    )
}