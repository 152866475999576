import styles from './echart.module.css'
import Title from './../title/title'
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import axios from 'axios'
import config from './../../assets/js/config'
import * as echarts from 'echarts'

const color = [
  'rgba(2, 167, 250, 1)',
  'rgba(0, 173, 150, 1)',
  'rgba(250, 250, 130, 1)',
  'rgba(255, 133, 51, 1)',
  'rgba(235, 84, 114, 1)',
]

const BarChart = ({ data }) => {
  let option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        label: {
          show: true,
        },
      },
      formatter: (res) => {
        console.log(res)
        const item = res[0].data.extraData
        let render = `${res[0].axisValue} <br/> 
          ${res[0].seriesName}:${item.disaster_percent}% <br/>
          ${res[1].seriesName}:${item.project_number}个 <br/>
          ${res[2].seriesName}:${item.project_money}万元 <br/> 
        `
        return render
      },
    },
    calculable: true,
    legend: {
      itemGap: 20,
      itemWidth: 6,
      itemHeight: 6,
      icon: 'circle',
      color: 'red',
      textStyle: {
        color: '#5CDEFF',
      },
    },
    grid: {
      top: '6%',
      left: '1%',
      right: '2%',
      bottom: '14%',
      containLabel: true,
    },
    // dataZoom:[{
    //   type:'inside',
    //   show:true,
    //   endValue:10,
    //   yAxisIndex: [0],
    //   zoomLock:true,
    //   left:'95%',
    //   width:5,
    // }],
    xAxis: [
      {
        type: 'value',
        // data: data.xData,
        axisLine: {
          //y轴线的颜色以及宽度
          show: true,
          lineStyle: {
            color: 'rgba(4,90,139,1)',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
          interval: 0,
          // rotate:90
          // formatter:function(value){
          //   return value.split("").join("\n");
          // }
        },
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: data.xData,
        // name: '数量(个)',
        nameTextStyle: {
          //y轴上方单位的颜色
          color: 'rgba(4,90,139,1)',
          padding: [-22, 0, 0, 0],
          verticalAlign: 'top',
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          //y轴线的颜色以及宽度
          show: true,
          lineStyle: {
            color: 'rgba(4,90,139,1)',
            width: 1,
            type: 'solid',
          },
        },
        axisLabel: {
          //y轴文字的配置
          // interval:30,
          // hideOverlap:false,
          textStyle: {
            color: '#5CDEFF',
            fontSize: 10,
          },
        },
      },
      // {
      //   type: 'value',
      //   name: '金额(万元)',
      //   nameTextStyle:{//y轴上方单位的颜色
      //     color: "rgba(4,90,139,1)",
      //     padding:[-22,0,0,0],
      //     verticalAlign: "top"
      //   },
      //   splitLine:{
      //     show:false
      //   },
      //   axisLabel: {//y轴文字的配置
      //     textStyle: {
      //       color: "rgba(4,90,139,1)",
      //     },
      //   },
      // },
    ],
    color: [
      {
        type: 'linear',
        x: 1,
        y: 0,
        x2: 0,
        y2: 0,
        colorStops: [
          // {offset:0,color:'rgba(128, 216, 255, 1)'},
          // {offset:1,color:'rgba(128, 216, 255, 0)'}
          // {offset:0,color:'rgba(22, 192, 155, 1)'},
          // {offset:1,color:'rgba(22, 192, 155, 0.1)'}
          { offset: 0, color: 'rgba(153, 255, 255, 1)' },
          { offset: 1, color: 'rgba(0, 33, 163, 0.2)' },
        ],
      },
      {
        type: 'linear',
        x: 1,
        y: 0,
        x2: 0,
        y2: 0,
        colorStops: [
          // {offset:0,color:'rgba(5, 147, 224, 1)'},
          // {offset:1,color:'rgba(5, 147, 224, 0.1)'}
          { offset: 0, color: 'rgba(223, 183, 99, 1)' },
          { offset: 1, color: 'rgba(49, 66, 90, 0.1)' },
        ],
      },
      {
        type: 'linear',
        x: 1,
        y: 0,
        x2: 0,
        y2: 0,
        colorStops: [
          // {offset:0,color:'rgba(38, 104, 235, 1)'},
          // {offset:1,color:'rgba(38, 104, 235, 0.1)'}
          { offset: 0, color: 'rgba(223, 133, 116, 1)' },
          { offset: 1, color: 'rgba(65, 75, 105, 0.1)' },
        ],
      },
    ],
    series: [
      {
        name: '支出占比',
        type: 'bar',
        // barWidth:3,
        // stack:'overlap',
        data: data.series[0],
        // yAxisIndex:1,
      },
      {
        name: '项目数量',
        type: 'bar',
        // barWidth:3,
        // stack:'overlap',
        data: data.series[1],
      },
      {
        name: '项目金额',
        type: 'bar',
        // barWidth:3,
        data: data.series[2],
        // yAxisIndex:1,
      },
    ],
  }

  useEffect(() => {
    const chartDom = document.getElementById('barChart')
    const myChart = echarts.init(chartDom)
    option && myChart.setOption(option)
  }, [data])

  return (
    <div className={styles.styleBox}>
      <div id="barChart" className={styles.barChart}></div>
    </div>
  )
}

const CircularDiagram = ({ data }) => {
  let allTotal = 0

  data.forEach((item) => {
    allTotal += item.value - 0
  })

  let option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}<br/> {c}万亩（{d}%）',
    },
    legend: {
      show: false,
      top: '5%',
      left: 'center',
    },
    title: {
      text: allTotal.toLocaleString(),
      subtext: '总金额(万元)',
      textStyle: {
        fontSize: 28,
        fontFamily: 'BebasNeue-Regular, BebasNeue',
        color: 'rgba(255, 255, 255, 1)',
        fontWeight: 400,
        textAlign: 'center',
      },
      subtextStyle: {
        fontSize: 12,
        color: 'rgba(56, 187, 235, 1)',
        fontWeight: 400,
      },
      left: 'center',
      top: '38%',
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['70%', '80%'],
        avoidLabelOverlap: false,
        itemStyle: {
          // borderRadius: 10,
          borderColor: 'RGBA(255, 255, 255, 0)',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 10,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        color: color,
        data: data,
      },
    ],
  }

  useEffect(() => {
    const chartDom = document.getElementById('main')
    const myChart = echarts.init(chartDom)
    option && myChart.setOption(option)
  }, [data])

  return (
    <div className={styles.styleBox}>
      <div id="main" className={styles.main}></div>
      <div className={styles.detailInfo}>
        {data.map((item, i) => {
          let percent = ((item.value / allTotal) * 100).toFixed(2)
          percent = isNaN(percent) || !percent ? 0 : percent
          return (
            <div className={styles.item} key={i}>
              <div className={styles.tit}>
                <i
                  style={{
                    background: `${color[typeof i != 'undefined' ? i : 0]}`,
                  }}
                ></i>
                {item.name}
              </div>
              <div className={styles.cont}>
                <div className={styles.total}>
                  {item.value.toLocaleString()}
                  <span>万元</span>
                </div>
                <div className={styles.split}></div>
                <div className={styles.total}>
                  {percent}
                  <span>%</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const ProjectOverview = forwardRef((props, ref) => {
  const { year = 2022 } = props

  useEffect(() => {
    getData(year)
  }, [])

  const [data, setData] = useState({})
  const [flag, setFlag] = useState(0)
  const [currentList, setCurrentList] = useState([])
  const [options, setOptions] = useState([
    { value: 0, label: '项目类型' },
    { value: 1, label: '行政区划' },
  ])

  const [sort, setSort] = useState(0)
  const getData = (year) => {
    axios(`${config.baseUrl}/lossstatic?year=${year}`).then(function (res) {
      const data = res.data
      const area = data.area
      const sort = data.sort

      const xData = []
      const seriesOne = []
      const seriesTwo = []
      const seriesThree = []
      const sortList = []

      area.sort((a, b) => {
        return a.disaster_percent_c - b.disaster_percent_c
      })

      area.forEach((item) => {
        xData.push(item.city)
        // seriesOne.push(item.project_money_c)
        // seriesTwo.push(item.project_number_c)
        // seriesThree.push(item.disaster_percent_c)

        seriesOne.push({
          value: item.project_money_c,
          name: item.city,
          extraData: item,
        })
        seriesTwo.push({
          value: item.project_number_c,
          name: item.city,
          extraData: item,
        })
        seriesThree.push({
          value: item.disaster_percent_c,
          name: item.city,
          extraData: item,
        })
      })

      sort.forEach((item) => {
        sortList.push({
          name: item.project_type,
          value: item.project_money,
        })
      })

      setData({
        xData,
        series: [seriesOne, seriesTwo, seriesThree],
      })
      setSort(sortList)
    })
  }

  // const [info, setInfo] = useState()
  // const [open, setOpen] = useState(false);
  // const getDetail = (waring_id) => {
  //     axios(`${config.baseUrl}/warning_detail?waring_id=${waring_id}`).then(function (res) {
  //         setInfo(res.data)
  //         setOpen(true)
  //     });
  // }

  // const showDetail = (waring_id) => {
  //     getDetail(waring_id)
  // }

  useImperativeHandle(ref, () => ({
    getData: (e) => {
      getData(e)
    },
  }))

  const handleChange = (value) => {
    setFlag(value)
  }
  return (
    <div className={styles.projectOverview}>
      {/* style={{width:'0.78rem'}} */}
      {/* <Title title='防灾减损费用占比' link="https://ynb.piccgd.com:6443/fzjs/admin/index.html#/project/warning_list_view" showSelect={true} options={options} handleChange={handleChange} hover={true} /> */}
      <Title
        title="防灾减损费用占比"
        showSelect={true}
        options={options}
        handleChange={handleChange}
        hover={true}
      />
      <div className={styles.content}>
        {flag == 0 && sort ? (
          <CircularDiagram data={sort} />
        ) : data.xData ? (
          <BarChart data={data} />
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default ProjectOverview
