import { useState } from 'react'
import './login.scss'
import config from '@/assets/js/config'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import md5 from 'js-md5'

const Login = () => {
  const navigate = useNavigate()
  const [account, setAccount] = useState()
  const [pwd, setPwd] = useState()
  const [code, setCode] = useState()

  const accountChange = (e) => {
    setAccount(e.target.value)
  }

  const pwdChange = (e) => {
    setPwd(e.target.value)
  }

  const codeChange = (e) => {
    setCode(e.target.value)
  }

  const submitHandle = () => {
    axios(
      `${config.baseUrl}/loginweb?account=${account}&pwd=${md5(pwd)}&code=${code}`
    ).then((res) => {
      if (res.data.code == 0) {
        alert(res.data.msg)
      } else {
        // $cookie.set('token', res.data.token)
        localStorage.setItem("token",res.data.token)

        //转跳到大屏首页
        navigate('/', { replace: true })
      }
    })
  }

  const refcode = (e) => {
    e.target.src = `${config.baseUrl}/getCode?t=${new Date().getTime()}`;
  }

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__title">广东省防灾减损全景图</div>
        <div className="login__content">
          <div className="login__label">账号登录</div>
          <div className="login__wirteContent">
            <div className="login__item">
              <input
                className="login__write"
                id="user"
                type="text"
                placeholder="请输入账号"
                onChange={accountChange}
              />
            </div>
            <div className="login__item">
              <input
                className="login__write"
                type="password"
                placeholder="请输入密码"
                onChange={pwdChange}
              />
            </div>
            <div className="login__item">
              <input
                className="login__write"
                type="text"
                placeholder="请输入动态验证码"
                onChange={codeChange}
              />
              <div className="login__checkCode">
                <img src={`${config.baseUrl}/getCode`}  onClick={refcode} alt="" />
              </div>
            </div>
            <div className="login__item">
              <button onClick={submitHandle}>登录</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
