import './index.scss'
import img_d3 from '@/assets/img/d3.gif'
import img_d4 from '@/assets/img/d4.png'
import img_d4_1 from '@/assets/img/d4_1.png'
import img_d5 from '@/assets/img/d5.png'
import img_d5_1 from '@/assets/img/d5_1.png'
import img_d6 from '@/assets/img/d6.png'
import img_d6_1 from '@/assets/img/d6_1.png'
import img_d7 from '@/assets/img/d7.png'
import img_d7_1 from '@/assets/img/d7_1.png'
import img_d8 from '@/assets/img/d8.png'
import img_d8_1 from '@/assets/img/d8_1.png'
import img_d9 from '@/assets/img/d9.png'
import img_d9_1 from '@/assets/img/d9_1.png'

export default function CenterAnimate({ isAni, aniData }) {
  const imgList = [
    {
      img: img_d4,
      imgHover: img_d4_1,
    },
    {
      img: img_d5,
      imgHover: img_d5_1,
    },
    {
      img: img_d6,
      imgHover: img_d6_1,
    },
    {
      img: img_d7,
      imgHover: img_d7_1,
    },
    {
      img: img_d8,
      imgHover: img_d8_1,
    },
    {
      img: img_d9,
      imgHover: img_d9_1,
    },
  ]

  return (
    <div className={`center-animate ${isAni ? 'show' : 'none'}`}>
      <div className="center-animate__container">
        <img className="center-animate__doll" src={img_d3} />
        <div className="center-animate__list">
          {aniData.map((v, i) => (
            <a key={i} className="center-animate__item" target='_blank' href={v.url}>
              <img
                className="center-animate__item__img"
                src={imgList[i].img}
              ></img>
              <img
                className="center-animate__item__imgHover"
                src={imgList[i].imgHover}
              ></img>
              <span>{v.name}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}
