import styles from './index.module.css'
import Title from './../title/title'
import Modal from './../modal/index'
// import data from './../../json/project_list.json'
import { useEffect, forwardRef, useImperativeHandle, useState } from 'react'
import { Carousel } from 'antd'
// import { List, Spin } from 'antd';
// import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios'
import config from './../../assets/js/config'

const ProjectOverview = forwardRef((props, ref) => {
  const { defaultDate } = props
  useEffect(() => {
    getData()
  }, [])

  const [data, setData] = useState([])
  const [params, setParams] = useState({})
  const getData = (obj = {}) => {
    // obj = Object.assign(obj,params)
    obj = Object.assign(params, obj)
    const { date, type, level, status = '全部', year = 2022 } = obj
    axios(
      `${config.baseUrl}/project_list?date=${
        typeof date == 'undefined' ? defaultDate : date
      }&type=${type}&level=${level}&status=${status}&year=${year}`
    ).then((res) => {
      //farm_work_id
      setData(res.data)
    })
  }

  const [info, setInfo] = useState()
  const [open, setOpen] = useState(false)
  const getDetail = (project_id) => {
    axios(`${config.baseUrl}/project_detail?project_id=${project_id}`).then(
      (res) => {
        setInfo(res.data)
        setOpen(true)
      }
    )
  }

  const showDetail = (project_id) => {
    getDetail(project_id)
  }

  const [options, useOptions] = useState([
    { value: '全部', label: '全部' },
    { value: '实施中', label: '实施中' },
    { value: '已结项', label: '已结项' },
  ])

  const handleChange = (value) => {
    getData({ status: value })
  }

  useImperativeHandle(ref, () => ({
    getData: (e) => {
      setParams(e)
      getData(e)
    },
  }))

  return (
    <div className={`center-bottom ${styles.projectOverview}`}>
      <Title
        title="项目一览"
        link="https://ynb.piccgd.com:6443/fzjs/admin/index.html#/project/list_view"
        showSelect={true}
        options={options}
        handleChange={handleChange}
        hover={true}
      />
      <Modal
        title="项目信息"
        type={2}
        open={open}
        setOpen={setOpen}
        info={info}
      />
      <div className={`${styles.content} projectList`}>
        <div className={`${styles.head} ${styles.item}`}>
          <div className={styles.citem}>区域</div>
          <div className={styles.citem}>项目类型</div>
          <div className={styles.citem}>项目名称</div>
          <div className={styles.citem}>项目日期</div>
        </div>
        <div className={`${styles.scrollBox} `} id="projectList">
          <Carousel
            autoplay
            dots={false}
            vertical={true}
            slidesToShow={5}
            slidesToScroll={1}
          >
            {data.map((item) => {
              return (
                <div key={item.name}>
                  <div
                    className={styles.item}
                    onClick={() => showDetail(item.id)}
                  >
                    <div className={`${styles.citem} eli`}>{item.area}</div>
                    <div className={`${styles.citem} eli`}>{item.type}</div>
                    <div className={`${styles.citem} eli`}>{item.name}</div>
                    <div className={`${styles.citem} eli`}>{item.date}</div>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  )
})

export default ProjectOverview
