/*
 * @Author: lichengming
 * @Date: 2023-01-12 13:39:19
 * @LastEditTime: 2023-01-12 13:55:19
 * @FilePath: /yuenongbao/src/assets/js/config.js
 * @Description: Description
 */
export default {
  baseUrl: 'https://yue.lijincai.com/api/api',
  //baseUrl: 'http://kube.gago.top:32641/api/api'
  //baseUrl: 'http://localhost:18090/api/api'
}
