import styles from "./index.module.css";
import Title from "./../title/title";
// import data from './../../json/video_list.json'
import { useEffect, useState } from 'react';
import axios from 'axios'
import config from "./../../assets/js/config";

export default function ProjectOverview() {

    useEffect(() => {
        getData()
    },[])

    const [data,setData] = useState();
    const getData = () => {
        axios(`${config.baseUrl}/video_list`).then(res => {
            setData(res.data)
        });
    }

    return <div className={styles.projectOverview}>
        <Title title='保险+农服 知识库' link="https://ynb.piccgd.com:6443/fzjs/admin/index.html#/project/classroom_view"/>
        <div className={styles.content}>
            {data && <video poster={data.poster} className={styles.video} controls>
                <source  src={data.url}  type="video/mp4" />
            </video>}
        </div>
    </div>
}