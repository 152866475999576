import { lazy, Suspense } from 'react'
import App from '@/pages/app/App'
import Login from '@/pages/login/login'
import { useNavigate, Navigate } from 'react-router-dom'
import config from '@/assets/js/config'
import axios from 'axios'

const Private = ({ children }) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  if (token) {
    return <>{children}</>
  } else {
    return <Navigate to={{ pathname: '/login', replace: true }} />
  }
}

export const routes = [
  {
    path: '/',
    element: (
      <Private>
        <App />
      </Private>
    ),
  },
  {
    path: '/login',
    element: <Login />,
  },
]
