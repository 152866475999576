import * as echarts from 'echarts';
import { useEffect, useState } from 'react';
import guangdong from "./city/guangdong.json";
import { throttle } from "./../../assets/js/api";
import tip from "./../../assets/img/tip.png";
// import data from "./../../json/project_map.json";
import axios from 'axios'
import config from "./../../assets/js/config";

// let myChart;
export default function Echart(props) {


  //接口返回的值不多，但是真实数据
    // const getData = () => {
    //   myChart.showLoading();
    //   axios("https://ynb.piccgd.com:6443/fzjs/api/api/project_map?date=2022-11-01~2022-12-30&type=种植&level=省级").then(res => {   
    //     return res.data
    //   }).then((data) => {
         
    //     let option = {
    //       geo: {
    //         map: 'guangdong',
    //         roam: false,
    //         zoom:1.1,
    //         label: {
    //             show: true,
    //             color:'RGBA(194, 247, 255, 1)',
    //             fontSize:12,
    //             emphasis: {
    //                 show: true,
    //                 textStyle: {
    //                     color: "#ffffff",
    //                     fontSize: 12,
    //                 },
    //             },
    //         },
    //         itemStyle: {
    //             areaColor: 'RGBA(12, 37, 61, 1)',
    //             shadowColor: 'RGBA(5, 24, 44, 1)',
    //             borderWidth: 1,
    //             borderColor:'RGBA(117, 218, 255, 1)',
    //             shadowOffsetX: 0,
    //             shadowOffsetY: 6,
    //         },
    //         tooltip:{
    //           show:true,
    //           backgroundColor:'rgba(50,50,50,0)',
    //           extraCssText: `width:196px;height:114px;background: url(${tip}) no-repeat;background-size: cover;box-sizing: border-box;box-shadow: initial`,
    //           formatter: (e)=>{
    //             let items = {}
    //             data.some(item => {
    //               if(item.name == e.name){
    //                 items = item
    //                 return true
    //               }
    //             })
    //             return `<div id="tooltip">
    //                     <div class="head"><span>${e.name}</span></div>
    //                     <div class="tcon">
    //                       <div class="item">
    //                         <span class="tit">项目数量：</span>
    //                         <span class="num">${items.DateItems[0].value}个</span>
    //                       </div> 
    //                       <div class="item">
    //                         <span class="tit">项目金额：</span>
    //                         <span class="num">${items.DateItems[1].value}万元</span>
    //                       </div> 
    //                     </div>
    //                   </div>`
    //           }
    //         }
    //       },
    //       // label: {
    //       //     show: true,
    //       //     color:'white',
    //       //     fontSize:16,
    //       // },
    //       emphasis: {
    //           itemStyle: {
    //               areaColor: 'RGBA(33, 45, 63, 1)',
    //               borderWidth: 2,
    //               borderColor:'RGBA(255, 135, 92, 1)',
    //           },
    //       }
    //     };
    
    //     const colorList = ['#4BB352','#FABF1E']
    //     const renderEchartsBar = () => {
    //         option = Object.assign(option, {
    //             xAxis: [],
    //             yAxis: [],
    //             grid: [],
    //             series: [],
    //             tooltip: {
    //               trigger: "item",
    //               position:'right',
    //               axisPointer: {
    //                 type: "none",
    //               },
    //             },
    //         });
    //         data.forEach((item, idx) => {
    //           let nodeCoord = item.gps;
    //           let coord = myChart.convertToPixel("geo", nodeCoord);
    //           let titleItems = item.DateItems.map((i) => i.title);
    //           let itemData = item.DateItems.map((i) => Number(i.value));
    //           // console.log(item, this.selfAreaItems, coord);
    //           if (coord) {
    //             option.xAxis.push({
    //               show:false,
    //               id: idx + item.name,
    //               gridId: idx + item.name,
    //               type: "category",
    //               name: item.name,
    //               nameLocation: "middle",
    //               nameGap: 3,
    //               splitLine: {
    //                 show: false,
    //               },
    //               axisTick: {
    //                 show: false,
    //               },
    //               axisLabel: {
    //                 show: false,
    //               },
    //               axisLine: {
    //                 onZero: false,
    //                 lineStyle: {
    //                   color: "#ffffff",
    //                 },
    //               },
    //               data: titleItems,
    //               z: 100,
    //             });
    //             option.yAxis.push({
    //               id: idx + item.name,
    //               gridId: idx + item.name,
    //               type: "value",
    //               splitLine: {
    //                 show: false,
    //               },
    //               axisTick: {
    //                 show: false,
    //               },
    //               axisLabel: {
    //                 show: false,
    //               },
    //               axisLine: {
    //                 show: false,
    //                 lineStyle: {
    //                   color: "red",
    //                 },
    //               },
    //               min: 0,
    //               max: "dataMax",
    //             });
    //             option.grid.push({
    //               id: idx + item.name,
    //               width: 15,
    //               height: 30,
    //               left: coord[0] - 15,
    //               top: coord[1] - 15,
    //               z: 10,
    //             });
    //             option.series.push({
    //               id: idx + item.name,
    //               type: "bar",
    //               xAxisId: idx + item.name,
    //               yAxisId: idx + item.name,
    //               barGap: 0,
    //               barCategoryGap: 0,
    //               data: itemData,
    //               barWidth: 7,
    //               z: 100,
    //               itemStyle: {
    //                 normal: {
    //                   color: (params) => {
    //                     // let color;
    //                     // console.log('params:',params)
    //                     // data.forEach((item) => {
    //                     //   if (item.name == params.seriesId.slice(1)) {
    //                     //     item.DateItems.forEach((i) => {
    //                     //       if (i.title == params.name) {
    //                     //         color = i.BgColor;
    //                     //       }
    //                     //     });
    //                     //   }
    //                     // });
    //                     // let color = this.selfColor[params.dataIndex];
    //                     let color = colorList[params.dataIndex]
    //                     return color;
    //                   },
    //                 },
    //                 opacity: 0,
    //                 emphasis: {
    //                   label: {
    //                     show: false,
    //                   },
    //                 },
    //               },
    //             });
    //           }
    //         });
    //         myChart.setOption(option);
    //       }

    //     const registerMap = () => {
    //       echarts.registerMap('guangdong', guangdong, {
    //           Alaska: {
    //             left: -131,
    //             top: 25,
    //             width: 15
    //           },
    //           Hawaii: {
    //             left: -110,
    //             top: 28,
    //             width: 5
    //           },
    //           // 'Puerto Rico': {
    //           //   left: -76,
    //           //   top: 26,
    //           //   width: 2
    //           // }
    //       });
    //   }

    //     registerMap()
    //     myChart.hideLoading();
    //     myChart.setOption(option);
    //     let throttledRenderEachCity = throttle(renderEchartsBar, 0);
    //     myChart.on("geoRoam", throttledRenderEachCity);
    //     renderEchartsBar()
    //   })
    // }


    // useEffect(()=>{
    //   const chartDom = document.getElementById('echarts');
    //   myChart = echarts.init(chartDom);
    //   getData()
    //   // myChart.showLoading();
    //   // registerMap()
    //   // myChart.hideLoading();
    //   // myChart.setOption(option);
    //   // let throttledRenderEachCity = throttle(renderEchartsBar, 0);
    //   // myChart.on("geoRoam", throttledRenderEachCity);
    //   // renderEchartsBar()
    // },[])

    

    
    
    
    
    
    return <div id="echarts" style={{flex:1}}></div>
}