/*
 * @Author: lichengming
 * @Date: 2023-01-17 14:00:33
 * @LastEditTime: 2023-02-16 02:20:01
 * @FilePath: /yuenongbao/src/components/typicalCases/index.js
 * @Description: Description
 */
import styles from "./index.module.css";
import Title from "./../title/title";
import Modal from "./../modal/index";
// import data from './../../json/project_demo.json'
import { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import axios from 'axios'
import config from "./../../assets/js/config";

const dots ={
    className:styles.typicalDots
}


export default function ProjectOverview() {

    useEffect(() => {
        getData()
    },[])

    const [data,setData] = useState([]);
    const getData = (project_id) => {
        axios(`${config.baseUrl}/project_demo?project_id=${project_id}`).then(res => {
            setData(res.data)
        });
    }

    const [info, setInfo] = useState()
    const [open, setOpen] = useState(false);
    const getDetail = (project_id) => {
        axios(`${config.baseUrl}/project_detail?project_id=${project_id}`).then(res => {    
            setInfo(res.data)
            setOpen(true)
        });
    }

    const showDetail = (project_id) => {
        getDetail(project_id)
    }

    return <div className={`${styles.projectOverview} typicalCases`}>
        <Title title='防灾减损典型案例' link="https://ynb.piccgd.com:6443/fzjs/admin/index.html#/project/demo_list_view" />
        <Modal title="项目信息" type={2} open={open} setOpen={setOpen} info={info}/>
        <div className={styles.content}>
            <Carousel autoplay  dots={dots}>
                {
                    data.map((item,i) => {
                        return <div className={styles.parent} key={item.project_id} onClick={() => showDetail(item.project_id)}>
                                    <img className={styles.img} src={item.project_images} alt={item.name} />
                                    <div className={styles.titleBox}><p className={styles.title}>{item.name}</p></div>
                                </div>
                    })
                }
            </Carousel>
        </div>
    </div>
}
