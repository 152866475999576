import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter, useRoutes } from 'react-router-dom'
import { routes } from '@/assets/js/router'
import axios from 'axios'
import './index.css'
import reportWebVitals from './reportWebVitals'
// import 'lib-flexible';
import './assets/js/flexible'

const Init = () => {
  const router = useRoutes(routes)
  return <>{router}</>
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <HashRouter>{<Init />}</HashRouter>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
